import { Form, Radio } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { commitMutation, createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { PROJECT_STATUSES, SAVING_STATES, SCHEDULING_TYPE } from "../../constants";
import { environment } from "../../relay";
import { handleErrorWithMessage } from "../../utils";
import { UpdateStudyInput } from "../../__generated__/ProjectDetailsPage_UpdateStudy_Mutation.graphql";
import { SlotsPage_study$data } from "../../__generated__/SlotsPage_study.graphql";
import { DetailsInput } from "../Configure/DetailsInput";
import { InputTimeZone } from "../Input";
import ProjectPageContent from "../ProjectPageContent";
import Saving, { SavingStateType } from "../Saving";
import CalendlyForm from "./CalendlyForm";
import { SessionsForm } from "./SessionsForm";

export type FormInputs =
  | "availabilitySlots"
  | "availabilityBuffer"
  | "meetingLink"
  | "schedulingType"
  | "schedulingLink";

const SlotsPage = ({ study }: { study: SlotsPage_study$data }) => {
  const [form] = Form.useForm();
  const [savingState, setSavingState] = useState<SavingStateType>("saved");

  const updateStudy = (input: UpdateStudyInput) =>
    new Promise((resolve, reject) => {
      const handleError = (err: any) => {
        handleErrorWithMessage(err, "Could not update study. Please try again later.");
        reject(err);
      };
      commitMutation(environment, {
        mutation: graphql`
          mutation SlotsPage_UpdateStudy_Mutation($input: UpdateStudyInput!) {
            updateStudy(input: $input) {
              study {
                id
                availabilitySlots {
                  edges {
                    node {
                      id
                      duration
                    }
                  }
                }
                ...SlotsPage_study
              }
            }
          }
        `,
        variables: { input },
        onCompleted: (response, errors) => {
          if (errors) return handleError(errors);
          resolve(response);
        },
        onError: handleError,
      });
    });

  // This useEffect syncs the availability slots in the back-end with the front-end, particularly
  // for the id of newly created slots which we need to pass to the back-end when editing slots
  useEffect(() => {
    if (form && study.availabilitySlots) {
      form.setFieldsValue(
        study.availabilitySlots.edges.length
          ? {
              availabilitySlots: study.availabilitySlots.edges.map(
                o =>
                  o?.node && {
                    id: o.node.id,
                    start: moment(o.node.start as string),
                    end: o.node.end,
                    duration: o.node.duration,
                    availabilityBuffer: o.node.availabilityBuffer,
                    interviewer: o.node.interviewer?.email,
                    inPerson: o.node.inPerson,
                    meetingLink: o.node.meetingLink,
                    meetingLocation: o.node.meetingLocation,
                    meetingDetails: o.node.meetingDetails,
                    placesLimit: o.node.placesLimit,
                  }
              ),
            }
          : {
              availabilitySlots: [
                {
                  id: null,
                  start: null,
                  end: null,
                  duration: null,
                  availabilityBuffer: null,
                  interviewer: null,
                  inPerson: false,
                  meetingLink: null,
                  meetingLocation: null,
                  meetingDetails: null,
                  placesLimit: null,
                },
              ],
            }
      );
    }
    // We don't want to include form in the dependencies as it will run too often
    // eslint-disable-next-line
  }, [study.availabilitySlots]);

  const saveInput = useCallback(
    async (name: FormInputs, value: any) => {
      // Used for updating fields on the Study model
      value = value === "" ? null : value;
      try {
        setSavingState(SAVING_STATES.SAVING);
        await updateStudy({ studyId: study.id, [name]: value });
        setSavingState(SAVING_STATES.SAVED);
      } catch {
        setSavingState(SAVING_STATES.ERROR);
        // In case the save fails, we save the value
        let rollbackValue = study[name];

        if (name === "availabilitySlots") {
          // Ignore TS warning that rollbackValue should be a string as it is a string when it needs to be
          // @ts-ignore
          rollbackValue = study.availabilitySlots.edges.length
            ? study.availabilitySlots.edges
                .map(
                  o =>
                    o?.node && {
                      id: o.node.id,
                      start: moment(o.node.start as string),
                      end: o.node.end,
                      interviewer: o.node.interviewer?.email,
                      inPerson: o.node.inPerson,
                      meetingLink: o.node.meetingLink,
                      meetingLocation: o.node.meetingLocation,
                      meetingDetails: o.node.meetingDetails,
                      placesLimit: o.node.placesLimit,
                    }
                )
                .filter(x => x)
            : [
                {
                  id: null,
                  start: null,
                  end: null,
                  interviewer: null,
                  inPerson: false,
                  meetingLink: null,
                  meetingLocation: null,
                  meetingDetails: null,
                  placesLimit: null,
                },
              ];
        }

        form.setFieldsValue({
          [name]: rollbackValue,
        });
      }
    },
    [form, study]
  );

  const scheduleTypeOptions = [{ label: "HubUX", value: SCHEDULING_TYPE.HUBUX }];

  if (!study.voxpopmeRecorderEnabled) {
    scheduleTypeOptions.push({ label: "Calendly", value: SCHEDULING_TYPE.CALENDLY });
  }

  return (
    <ProjectPageContent
      style={{ padding: 0, maxHeight: "100%", overflowY: "auto" }}
      extra={
        <>
          <Saving savingState={savingState} style={{ position: "initial" }} type="text" />
          <InputTimeZone style={{ minWidth: 160 }} />
        </>
      }
      title="Set up your meetings"
    >
      <Styled>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            schedulingType: study.schedulingType,
            schedulingLink: study.schedulingLink,
          }}
        >
          {!study.voxpopmeRecorderEnabled && (
            <DetailsInput
              title="Scheduling Method"
              description={"Choose your preferred tool for scheduling"}
              inputs={
                <Form.Item name="schedulingType" style={{ marginBottom: 4 }}>
                  <Radio.Group
                    options={scheduleTypeOptions}
                    onChange={e => saveInput("schedulingType", e.target.value)}
                    optionType="button"
                    buttonStyle="solid"
                    size="large"
                    disabled={study.status !== PROJECT_STATUSES.DRAFT}
                  />
                </Form.Item>
              }
            />
          )}
          {study.schedulingType === SCHEDULING_TYPE.CALENDLY && <CalendlyForm {...{ form, saveInput }} />}
        </Form>
        {study.schedulingType === SCHEDULING_TYPE.HUBUX && <SessionsForm {...{ study, savingState, setSavingState }} />}
      </Styled>
    </ProjectPageContent>
  );
};

const Styled = styled.section`
  padding: 24px 24px 48px;
  width: 100%;

  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;

export default createFragmentContainer(SlotsPage, {
  study: graphql`
    fragment SlotsPage_study on StudyNode {
      id
      dId
      type
      status
      availabilitySlots {
        edges {
          node {
            id
            start
            end
            duration
            availabilityBuffer
            interviewer {
              email
            }
            inPerson
            meetingLink
            meetingLocation
            meetingDetails
            placesLimit
            countOfBookedSlots
          }
        }
      }
      availabilityBuffer
      schedulingType
      schedulingLink
      meetingLink
      approvedCount
      voxpopmeRecorderEnabled
      ...SessionsForm_study
    }
  `,
});
