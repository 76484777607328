import { Button, Form } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { Link, useRouter } from "found";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { ConfigProviderAccent } from "../../../antd";
import { HEADER_FONT_SIZE, PAGE_WIDTH_MD, PAGE_WIDTH_SM, SUBTITLE_FONT_SIZE } from "../../../style";
import { flattenEdges, sentenceCase, useBoundForm, useMutation } from "../../../utils";

import { getFaviconEl } from "../../../utils/misc";
import type { PortalProfileComplete_characteristic$key } from "../../../__generated__/PortalProfileComplete_characteristic.graphql";
import type { PortalProfileComplete_panelist$key } from "../../../__generated__/PortalProfileComplete_panelist.graphql";
import { PortalProfileComplete_UpdatePanelist_Mutation } from "../../../__generated__/PortalProfileComplete_UpdatePanelist_Mutation.graphql";
import { PortalFooter } from "../PortalFooter";
import { PortalPageLayout } from "../PortalPageLayout";
import { useCustomPortalSanitized } from "../utils";
import { FormItemCharacteristicResponses } from "./FormItemCharacteristicResponses";
import { createGridDefaultValue, createUpdatePanelistInitialValues } from "./utils";

export const PortalProfileComplete = ({
  characteristic: characteristicKey,
  characteristicNextId,
  from,
  panelist: panelistKey,
}: {
  characteristic: PortalProfileComplete_characteristic$key;
  characteristicNextId?: string;
  from?: string;
  panelist: PortalProfileComplete_panelist$key;
}) => {
  const { match, router } = useRouter();

  const characteristic = useFragment(
    graphql`
      fragment PortalProfileComplete_characteristic on CharacteristicNode {
        id
        type
        text
        responses {
          edges {
            node {
              answer {
                id
                userSpecified
              }
              customAnswer
              panelist {
                id
              }
              ...FormItemCharacteristicResponses_characteristicResponses
            }
          }
        }
        ...FormItemCharacteristicResponses_characteristic
      }
    `,
    characteristicKey
  );
  const panelist = useFragment(
    graphql`
      fragment PortalProfileComplete_panelist on PanelistNode {
        customPanelistPortal {
          contactEmail
          customColor
          fadeBgBottom
          faviconUrl
          footerImageUrl
          headerMarkup
          headerMarkupEnabled
          heroAssetUrl
          heroBackgroundUrl
          hidePanelistProfile
          pageTitle
          privacyPolicyUrl
          termsLabel
          termsUrl
        }
        id
      }
    `,
    panelistKey
  );

  const [commit, isInFlight] = useMutation<PortalProfileComplete_UpdatePanelist_Mutation>(graphql`
    mutation PortalProfileComplete_UpdatePanelist_Mutation($input: UpdatePanelistInput!) {
      updatePanelist(input: $input) {
        panelist {
          id
          ...PortalProfileComplete_panelist
          selectedResponses {
            key
            answers {
              key
              selected
            }
            characteristic {
              id
              ...PortalProfileComplete_characteristic
            }
          }
        }
      }
    }
  `);

  const createInitialValues = (_characteristic: typeof characteristic) =>
    createUpdatePanelistInitialValues([
      {
        characteristic,
        characteristicResponses: flattenEdges(_characteristic.responses).filter(x => x.panelist.id === panelist.id),
      },
    ]);

  const { form, initialValues, onFinish, onReset, onValuesChange, touched } = useBoundForm(characteristic, {
    createInitialValues,
    onFinish: changedValues =>
      commit(
        {
          panelistId: panelist.id,
          data: changedValues,
        },
        {
          onCompleted: res => {
            if (!characteristicNextId) return router.push(`/portal`);

            const characteristicFollowingId = res.updatePanelist.panelist.selectedResponses.filter(
              x => x.characteristic.id !== characteristicNextId && !x.answers.some(x => x.selected)
            )[0]?.characteristic.id;

            const qs = new URLSearchParams({
              from: `${match.location.pathname}${match.location.search}`,
              ...(characteristicFollowingId ? { next: characteristicFollowingId } : {}),
            }).toString();

            router.push(`/portal/profile/complete/${characteristicNextId}${qs ? `?${qs}` : ""}`);
          },
        }
      ),
  });

  const customPortal = panelist?.customPanelistPortal;
  const { contactEmail } = useCustomPortalSanitized(customPortal as any);

  useEffect(() => {
    // Set the favicon and page title for whitelabeled sites
    const faviconEl = getFaviconEl();
    if (faviconEl && customPortal?.faviconUrl) faviconEl.href = customPortal?.faviconUrl;

    document.title = `Edit profile | ${customPortal?.pageTitle ?? "HubUX"}`;
  }, [customPortal]);

  const { t } = useTranslation();

  return panelist.id ? (
    <StyledPortalPageLayout
      customColor={customPortal?.customColor}
      footer={
        <PortalFooter
          privacyPolicyLink={customPortal?.privacyPolicyUrl}
          termsLabel={customPortal?.termsLabel}
          termsUrl={customPortal?.termsUrl}
          customFooterUrl={customPortal?.footerImageUrl}
          contactEmail={contactEmail}
        />
      }
    >
      <header className="text-center">
        <h1>{t("portal-profile-complete.title")}</h1>
        <p>
          {t("portal-profile-complete.subtitle")} <strong>{t("portal-profile-complete.optional")}</strong>
        </p>
      </header>
      <Form
        disabled={isInFlight}
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChange}
      >
        <header>
          <ReactMarkdown linkTarget="blank">{characteristic.text ?? ""}</ReactMarkdown>
        </header>
        <FormItemCharacteristicResponses
          characteristic={characteristic}
          characteristicResponses={flattenEdges(characteristic.responses).filter(x => x.panelist.id === panelist.id)}
          gridDefaultValue={createGridDefaultValue(characteristic.id, initialValues)}
          onValuesChange={onValuesChange}
        />
        <div className="hub-form-actions">
          <ConfigProviderAccent>
            {!!from && (
              <Link to={from}>
                {({ href, onClick }) => (
                  <Button disabled={isInFlight} href={href} onClick={onClick} role="link">
                    {sentenceCase(t("dictionary.adverb.back"))}
                  </Button>
                )}
              </Link>
            )}
            <Button disabled={isInFlight || !touched} htmlType="submit" loading={isInFlight} type="primary">
              {sentenceCase(characteristicNextId ? t("dictionary.adjective.next") : t("dictionary.verb.finish"))}
            </Button>
          </ConfigProviderAccent>
        </div>
      </Form>
    </StyledPortalPageLayout>
  ) : null;
};

const StyledPortalPageLayout = styled(PortalPageLayout)`
  --portal-profile-complete-space: 16px;

  @media (min-width: ${PAGE_WIDTH_SM}) {
    --portal-profile-complete-space: 32px;
  }

  .hub-layout-children {
    display: grid;
    grid-template-columns: 1fr;
    place-content: center;
    justify-items: center;
    padding: 32px var(--portal-profile-complete-space) calc(2 * var(--portal-profile-complete-space));
    gap: var(--portal-profile-complete-space);
  }

  .text-center {
    text-align: center;
  }

  h1 {
    font-size: ${HEADER_FONT_SIZE};
    margin-bottom: 0.25em;
  }

  form {
    background-color: white;
    border-radius: 2px;
    width: 100%;
    max-width: ${PAGE_WIDTH_MD};
    padding: var(--portal-profile-complete-space);

    header {
      font-size: ${SUBTITLE_FONT_SIZE};
    }
  }

  .hub-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
`;
